.root {
  --blog-card--border-radius: var(--g--card-border-radius);
  --blog-card--gap: var(--g--card-gap);
  --blog-card--width: var(--g--card-landscape-large-width);
  --blog-card--height: calc(var(--g--card-landscape-large-height) + 220px);
  --blog-card--width-unitless: var(--g--card-landscape-large-width-unitless);
  --blog-card--active-scale-factor: calc(
    (var(--blog-card--width-unitless) + 16) / var(--blog-card--width-unitless)
  );

  content-visibility: auto;
  contain-intrinsic-size: var(--blog-card--width) var(--blog-card--height);

  position: relative;
  transform: scale(1);

  container-type: inline-size;
  contain: strict;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;

  width: var(--blog-card--width);
  height: var(--blog-card--height);

  color: var(--g--color-white);
  text-decoration: none;

  background: var(--g--glass-dark-100);
  border-radius: var(--blog-card--border-radius);
  outline: 0;
  box-shadow:
    inset 0 1px 0 0 rgb(255 255 255 / 0%),
    inset 0 0 0 1px var(--g--glass-light-100),
    var(--g--shadow-md);

  transition:
    transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing),
    box-shadow calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);

  &.isActive {
    transform: scale(var(--blog-card--active-scale-factor));
    box-shadow:
      var(--g--inset-highlight-md),
      inset 0 0 0 1px var(--g--glass-light-500),
      var(--g--shadow-md);
  }

  html[data-nav-device='keyboard'] &:focus-within {
    outline: 1px solid var(--g--color-white);
    outline-offset: 3px;
  }
}

.bgActive {
  position: absolute;
  inset: 1px;

  display: block;

  opacity: 0;
  background: linear-gradient(180deg, rgb(248 114 92 / 10%) 0%, rgb(158 38 81 / 20%) 100%);
  border-radius: calc(var(--blog-card--border-radius) - 1px);

  transition: opacity calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);

  .isActive & {
    opacity: 1;
  }
}

.imageContainer {
  pointer-events: none;

  position: relative;

  display: block;

  aspect-ratio: 16 / 9;
  margin: 4px 4px 0;

  border-radius: calc(var(--blog-card--border-radius) - 4px);
}

.image {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center center;
  border-radius: inherit;
}

.imageHighlight {
  pointer-events: none;

  position: absolute;
  inset: 0;

  display: block;

  color: transparent;

  background: transparent;
  border-radius: inherit;
  box-shadow: var(--g--inset-highlight-md);
}

.title {
  pointer-events: none;

  position: relative;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;

  margin: var(--g--card-gap);

  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.15;
  color: var(--g--color-white);
  letter-spacing: -0.24px;
  word-break: break-word;
}

.description {
  pointer-events: none;

  position: relative;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;

  margin: 0 var(--g--card-gap);

  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  color: var(--g--color-white);
  word-break: break-word;
}

.icon {
  position: absolute;
  right: var(--g--margin-md);
  bottom: var(--g--margin-md);

  display: block;

  width: 22px;
  height: 22px;

  color: var(--g--glass-light-500);

  transition: color var(--g--anim-duration) var(--g--anim-easing);

  .isActive & {
    color: var(--g--color-ray-orange);
  }
}
